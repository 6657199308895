import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import { useSetAtom } from 'jotai';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { profileTabIndex } from '../../../Atoms/Profile/profile';
import { Routes } from '../../../Constants/constants';
import CustomModal from '../../../lib/UI-Components/Modal';

import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel';
import PlaceholderImage from '../../../lib/UI-Components/PlaceholderImage'; // @ts-ignore
import EssayWritingMan from './../../../Assets/images/essay-writing.svg';

interface ProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ProfileModal: React.FC<ProfileModalProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { trackEvent } = useMixpanel();

  const setProfileTabIndex = useSetAtom(profileTabIndex);

  const handleNavigation = () => {
    navigate(Routes.Profile);
    setProfileTabIndex(0);
    trackEvent('Profile Modal Clicked ');
    onClose();
  };

  const renderHeader = () => (
    <Text fontWeight={700} fontSize={'md'} fontStyle={'normal'} lineHeight={'normal'}>
      Still haven’t completed your profile?
    </Text>
  );

  const renderFooter = () => (
    <HStack spacing={2} justifyContent='flex-end' width={'100%'}>
      <Button bg='primary' borderRadius={'8px'} onClick={handleNavigation}>
        Go to Profile
      </Button>
    </HStack>
  );

  const renderBody = () => (
    <VStack width={'100%'} alignItems={'flex-start'} spacing={6}>
      <VStack width={'100%'} alignItems={'center'} padding={2}>
        <PlaceholderImage imageSrc={EssayWritingMan} imageWidth='298px' imageHeight='211px' alt='Illustration' />
      </VStack>
      <VStack spacing={2} width={'100%'} alignItems={'flex-start'}>
        <Text mt={2} fontSize={'14px'} fontWeight={500} color='gray17' lineHeight={'140%'} fontStyle={'normal'}>
          Completing your profile takes just{' '}
          <Text as='span' fontWeight='bold'>
            1 minute
          </Text>
          . We suggest you complete it. It’ll multiply your chances by{' '}
          <Text as='span' fontWeight='bold'>
            50%
          </Text>{' '}
          of matching with the best fit colleges.
        </Text>
      </VStack>
    </VStack>
  );

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      header={renderHeader()}
      body={renderBody()}
      footer={renderFooter()}
      size={['sm', 'md']}
      contentProps={{
        style: {
          borderRadius: '12px',
        },
      }}
      bodyProps={{
        paddingX: 6,
        paddingY: 4,
      }}
      headerProps={{
        borderBottom: '1px solid',
        borderColor: 'gray12',
      }}
      footerProps={{
        borderTop: '1px solid',
        borderColor: 'gray12',
        paddingX: 4,
      }}
    />
  );
};

export default ProfileModal;
