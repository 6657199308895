import { useMutation } from '@tanstack/react-query';
import { MESSAGES_TYPE } from 'Constants/chatbots';
import chatbotService from 'Services/chatbot';
import { Dispatch, SetStateAction } from 'react';

const useSendMessage = (
  setMessages: Dispatch<SetStateAction<any[]>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) =>
  useMutation({
    mutationFn: ({ payload }: any) => chatbotService.sendMessage(payload),
    onSuccess: (data: any) => {
      if (data?.updated_conversation?.conversation?.length > 0) {
        const lastIndex = data?.updated_conversation?.conversation?.length - 1;

        setMessages((prev: any) => {
          const updatedMessage = {
            id: lastIndex + 1,
            content: data?.updated_conversation?.conversation[lastIndex]?.content,
            role: MESSAGES_TYPE.BOT,
            timestamp: new Date(),
            isLoading: false,
          };

          const updatedMessages = [...prev];
          updatedMessages[updatedMessages?.length - 1] = updatedMessage;
          return updatedMessages;
        });
      }

      setIsLoading(false);
    },
    onError: () => {
      setMessages((prev: any) => {
        const updatedMessage = {
          id: prev?.length,
          content: 'Sorry, something went wrong. Please try again.',
          role: MESSAGES_TYPE.BOT,
          timestamp: new Date(),
          isLoading: false,
        };

        const updatedMessages = [...prev];
        updatedMessages[updatedMessages?.length - 1] = updatedMessage;
        return updatedMessages;
      });
      setIsLoading(false);
    },
  });

export { useSendMessage };
