import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { ACTIONS, EVENTS } from 'react-joyride';
import { useLocation, useNavigate } from 'react-router-dom';
import { userAtom, userFirstNameAtom } from '../../Atoms/App/user';
import { selectedFeatureTabAtom, toolBarRefAtom } from '../../Atoms/EssayWriter/essayWriter';
import { isCreateNewEssayModalOpenObject } from '../../Atoms/Essays/essayAtom';
import { isAccordionOpenAtom } from '../../Atoms/Essays/essaySelectionAtom';
import { demoAtom, demoChoiceLinkAtom, firstEssayIdAtom } from '../../Atoms/demoAtom';
import { getDemoSteps, navbarSteps } from '../../Components/TourSteps';
import { PrivateRoutes, Routes } from '../../Constants/constants';
import { getLocalStorageKeys } from '../../Constants/demo';
import { ESSAY_FEATURES_TAB } from '../../Constants/essay';
import { delay, isMobile, isTablet } from '../../Utils/common';
import { convertEssayWriterRoute } from './../../Utils/app';

const useJoyRide = () => {
  const [demo, setDemo] = useAtom(demoAtom);
  const setIsAccordionOpen = useSetAtom(isAccordionOpenAtom);
  const setBrainstormerModal = useSetAtom(isCreateNewEssayModalOpenObject);
  const setSelectedFeatureTab = useSetAtom(selectedFeatureTabAtom);
  const user = useAtomValue(userAtom);
  const navigate = useNavigate();
  const userFirstName = useAtomValue(userFirstNameAtom);
  const location = useLocation();
  const firstEssayId = useAtomValue(firstEssayIdAtom);
  const toolbarRef = useAtomValue(toolBarRefAtom);
  const [demoChoiceLink, setDemoChoiceLink] = useAtom(demoChoiceLinkAtom);

  useEffect(() => {
    const visitedPages: any = localStorage.getItem('visited') as string;
    const storedPageVisitedValues = JSON.parse(visitedPages);
    const isSameUser = storedPageVisitedValues?.user?.id === user?.id;
    const newPageVisitedValues = { ...storedPageVisitedValues };
    const localStorageKeys = getLocalStorageKeys();

    const isUserOnDemoRoute = PrivateRoutes.includes(convertEssayWriterRoute(location?.pathname) as Routes);

    if (
      storedPageVisitedValues?.pageVisits &&
      isSameUser &&
      !storedPageVisitedValues.pageVisits[localStorageKeys[convertEssayWriterRoute(location?.pathname)]] &&
      isUserOnDemoRoute
    ) {
      const demoSteps = getDemoSteps(userFirstName || '');
      const demoInfo = demoSteps[convertEssayWriterRoute(location?.pathname)];

      const localStorageKey = localStorageKeys[convertEssayWriterRoute(location?.pathname)];

      newPageVisitedValues.pageVisits[localStorageKey] = true;
      localStorage.setItem('visited', JSON.stringify(newPageVisitedValues));
      setTimeout(() => {
        setDemo((prev) => ({
          ...prev,
          showJoyride: true,
          index: 0,
          screen: convertEssayWriterRoute(location?.pathname),
          steps: demoInfo?.steps || [],
          isControlled: !demoInfo?.continuous,
        }));
      }, 500);
    } else if (demo?.isFirstSkip && !demo?.isNavDemoShown) {
      setTimeout(() => {
        setDemo((prev) => ({
          ...prev,
          showJoyride: true,
          index: 0,
          screen: convertEssayWriterRoute(location?.pathname),
          steps: navbarSteps,
          isControlled: true,
          isFirstSkip: false,
          isNavDemoShown: true,
        }));
      }, 500);
    }
  }, [location, demo?.isFirstSkip]);

  const reset = useCallback((isFirstTimeSkip = false) => {
    setDemo((prev) => ({
      ...prev,
      showJoyride: false,
      manualTrigger: false,
      isApplicationBackgroundOpen: false,
      isPlannerCollegesListOpen: false,
      index: 0,
      steps: [],
      isFirstSkip: isFirstTimeSkip,
    }));
  }, []);

  const verifyDelay = async (step = { target: '' } as any) => {
    if (step?.target === '.essay-create-section') {
      setIsAccordionOpen(false);
      setBrainstormerModal((prev) => ({
        ...prev,
        isCreateNewEssayModalOpen: true,
      }));
      await delay(100);
    } else if (step.target === '.planner-smart-plan') {
      setDemo((prev) => ({
        ...prev,
        isPlannerCollegesListOpen: true,
      }));
      await delay(100);
    } else if (step.target === '.essay-writer-writing-tools') {
      setSelectedFeatureTab(ESSAY_FEATURES_TAB.EssayReview);
      await delay(100);
    } else if (step.target === '.essay-writer-review-button' || step.target === '.essay-writer-review-button-mobile') {
      setSelectedFeatureTab(ESSAY_FEATURES_TAB.EssayPlan);
      await delay(200);
    } else if (
      step.target === '#header' &&
      (isMobile() || isTablet()) &&
      location?.pathname?.startsWith('/essays/editor')
    ) {
      if (toolbarRef) {
        toolbarRef.scrollLeft = toolbarRef.scrollWidth;
      }

      await delay(200);
    }
  };

  const nextStep = useCallback(
    async (step = { target: '' } as any) => {
      await verifyDelay(step);
      setDemo((prev) => ({ ...prev, index: prev.index + 1 }));
    },
    [demo?.steps?.length]
  );

  const transitionToOtherScreen = useCallback(() => {
    reset();

    const nextDemoScreen = convertEssayWriterRoute(demoChoiceLink);

    if (nextDemoScreen === Routes.EssayWriter) {
      if (firstEssayId) {
        setDemoChoiceLink('');
        navigate(Routes.EssayWriter.replace(':id', String(firstEssayId)));
      }
    } else {
      setDemoChoiceLink('');
      navigate(nextDemoScreen);
    }
  }, [demoChoiceLink, firstEssayId, navigate, reset]);

  const handleCallback = async (data) => {
    const { action, type, step } = data;

    if ([ACTIONS.RESET, ACTIONS.CLOSE].includes(action)) {
      reset();
    }

    if ([EVENTS.STEP_BEFORE].includes(type)) {
      if (step.target === '.essay-create-section') {
        setIsAccordionOpen(true);
      }
    }

    if ([EVENTS.STEP_AFTER].includes(type)) {
      if (step.target === '#brainstormer-modal-section') {
        setBrainstormerModal((prev) => ({
          ...prev,
          isCreateNewEssayModalOpen: false,
        }));
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else if (step.target === '#header') {
        setDemo((prev) => ({
          ...prev,
          isApplicationBackgroundOpen: true,
        }));
      }
    }
  };

  return {
    handleCallback,
    nextStep,
    reset,
    transitionToOtherScreen,
  };
};

export default useJoyRide;
