import { ACTIVITY_CATEGORIES, ACTIVITY_GRADES } from 'Constants/activity';
import { atom } from 'jotai';
import { EnvId } from 'Models/config';
import { Activity, ChartData, IHoursData } from '~/Models/activities';

export const filteredActivitiesAtom = atom<Activity[]>([]);

// Add Revamped code below

export const openActivityModal = atom<boolean>(false);
export const openUploadModal = atom<boolean>(false);
export const currentActivityModalPage = atom<number>(1);

export const breadcrumbViewState = atom<number>(1);
export const activitiesAtom = atom<Activity[]>([]);
export const loadingActivitiesAtom = atom<boolean>(true);
export const isAIDisableAtom = atom<boolean>(false);
export const leadershipCountAtom = atom<number | null>(0);
export const communityCountAtom = atom<number | null>(0);

export const polarChartDataAtom = atom<ChartData[]>(
  Object.keys(ACTIVITY_CATEGORIES)?.map((category) => ({ subject: category, count: 0 }))
);

export const barChartDataAtom = atom<IHoursData[]>(
  Object.keys(ACTIVITY_GRADES)?.map((grade) => ({ name: grade, value: 0 }))
);

export const isFormDirty = atom<boolean>(false);
export const isUpdateModalOpen = atom<boolean>(false);
export const isMobileAIOpen = atom<boolean>(false);
export const selectedChartOption = atom<string | null>(null);
export const aiSuggestionActivityID = atom<number | null>(null);
export const currentActivityTitle = atom<string | null>(null);
export const currentActivityDescription = atom<string | null>(null);
export const activityScore = atom<number>(0);

if (process.env.REACT_APP_NODE_ENV === EnvId.DEV) {
  activitiesAtom.debugLabel = 'activities';
}
