import { editorHeaderRefAtom, essayPromptExpandedAtom } from 'Atoms/EssayWriter/essayWriter';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

const useDynamicEditorHeight = (toolbarHeight = 250) => {
  const [editorHeight, setEditorHeight] = useState(0);
  const headerRef = useAtomValue(editorHeaderRefAtom);
  const promptExpanded = useAtomValue(essayPromptExpandedAtom);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateEditorHeight = () => {
    const headerHeight = headerRef?.current ? headerRef?.current?.offsetHeight : 0;
    const newHeight = window.innerHeight - headerHeight - toolbarHeight;
    setEditorHeight(newHeight);
  };

  useEffect(() => {
    updateEditorHeight();

    window.addEventListener('resize', updateEditorHeight);

    return () => {
      window.removeEventListener('resize', updateEditorHeight);
    };
  }, [toolbarHeight, headerRef, promptExpanded, updateEditorHeight]);

  return { editorHeight, headerRef, updateEditorHeight };
};

export default useDynamicEditorHeight;
