import { Routes } from 'Constants/constants';

export enum DEMO_LOTTIE_LINKS {
  notesAnimation = 'https://cdn.kollegio.ai/Notes.json',
  essayPromptsAnimation = 'https://cdn.kollegio.ai/PromptEssay.json',
  freeWriteAnimation = 'https://cdn.kollegio.ai/FreeWrite.json',
  essayReviewAnimation = 'https://cdn.kollegio.ai/Review.json',
  aiToolsAnimation = 'https://cdn.kollegio.ai/AITools.json',
  collegeTableAnimation = 'https://cdn.kollegio.ai/Colleges.json',
  profileTagAnimation = 'https://cdn.kollegio.ai/ProfileTag.json',
  categoryBreakdownAnimation = 'https://cdn.kollegio.ai/CategoryBreakdown.json',
  activityTimeSummaryAnimation = 'https://cdn.kollegio.ai/ActivityTimeSummary.json',
  activityFormFillingAnimation = 'https://cdn.kollegio.ai/ActivityFormFilling.json',
  activityAISuggestionAnimation = 'https://cdn.kollegio.ai/ActivityIndividual.json',
  openDemoAnimation = 'https://cdn.kollegio.ai/HowToOpenDemo.json',
  plannerSmartPlanAnimation = 'https://cdn.kollegio.ai/SmartPlan.json',
  plannerShortlistCollegesAnimation = 'https://cdn.kollegio.ai/PlannerShortlistedColleges.json',
  brainstormFinal = 'https://cdn.kollegio.ai/BrainstormerFinal.json',
}
export enum DEMO_SVG_LINKS {
  demoEssaySelectionMain = 'https://cdn.kollegio.ai/demo-essay-selection-main.svg',
  demoActivitiesMain = 'https://cdn.kollegio.ai/demo-activities-main.svg',
  demoEssayEditorMain = 'https://cdn.kollegio.ai/demo-essay-editor-main.svg',
  demoEssayPlan = 'https://cdn.kollegio.ai/demo-essay-plan.svg',
  demoActivitiesAiSuggestions = 'https://cdn.kollegio.ai/demo-activities-ai-suggestion.svg',
  demoActivitiesList = 'https://cdn.kollegio.ai/demo-activities-list.svg',
  demoDashboardMain = 'https://cdn.kollegio.ai/demo-dashboard-main.svg',
  demoUpcomingDealines = 'https://cdn.kollegio.ai/demo-upcoming-deadlines.svg',
  demoEssaysCard = 'https://cdn.kollegio.ai/demo-essays-card.svg',
  demoActivitiesCard = 'https://cdn.kollegio.ai/demo-activities-card.svg',
  demoCollegesMain = 'https://cdn.kollegio.ai/demo-colleges-main.svg',
  demoProfile = 'https://cdn.kollegio.ai/demo-profile.svg',
  demoBackground = 'https://cdn.kollegio.ai/demo-background.svg',
  demoPlannerMain = 'https://cdn.kollegio.ai/demo-planner-main.svg',
  demoPlannerGenerateButton = 'https://cdn.kollegio.ai/demo-planner-generate-button.svg',
}

export const nextScreen = {
  [Routes.Home]: Routes.Profile,
  [Routes.Profile]: Routes.Colleges,
  [Routes.Colleges]: Routes.Essays,
  [Routes.Essays]: Routes.EssayWriter,
  [Routes.EssayWriter]: Routes.Plan,
  [Routes.Plan]: Routes.Home,
};

export const getNextScreenLabel = () => ({
  [Routes.Home]: 'Profile',
  [Routes.Profile]: 'Colleges',
  [Routes.Colleges]: 'Essays',
  [Routes.Essays]: 'Essay Editor',
  [Routes.EssayWriter]: 'Planner',
  [Routes.Plan]: 'Dashboard',
});

export const getLocalStorageKeys = () => ({
  [Routes.Home]: 'home',
  [Routes.Profile]: 'profile',
  [Routes.Colleges]: 'colleges',
  [Routes.Essays]: 'essay_selection',
  [Routes.EssayWriter]: 'essay_editor',
  [Routes.Activities]: 'activities',
  [Routes.Plan]: 'plan',
});

export const DEMO_CHOICE_BUTTON_LABELS = [
  {
    label: '🎓 Finding Colleges',
    link: Routes.Colleges,
  },
  {
    label: '✍🏻 Essay Feedback',
    link: Routes.EssayWriter,
  },
  {
    label: '🗺️ Organize Deadlines',
    link: Routes.Plan,
  },
  {
    label: '🧠 Brainstorming Essay Topics',
    link: Routes.Essays,
  },
  {
    label: '🏀 Extracurricular Feedback',
    link: Routes.Activities,
  },
];
