import { OrderedList, Text, UnorderedList } from '@chakra-ui/react';
import React from 'react';

export const customRenderers = (color = 'gray5') => ({
  p: (props) => (
    <Text fontSize={'sm'} color={color}>
      {props.children}
    </Text>
  ),
  h1: (props) => (
    <Text fontSize={'sm'} color={color} fontWeight='bold'>
      {props.children}
    </Text>
  ),
  h2: (props) => (
    <Text fontSize={'sm'} color={color} fontWeight='bold'>
      {props.children}
    </Text>
  ),
  li: (props) => (
    <Text fontSize={'sm'} color={color}>
      {props.children}
    </Text>
  ),
  a: (props) => (
    <Text as='a' fontSize={'sm'} color={color} href={props.href}>
      {props.children}
    </Text>
  ),
  h3: (props: any) => (
    <Text fontSize={'sm'} fontFamily={'Montserrat'} color={color} fontWeight='bold'>
      {props.children}
    </Text>
  ),
  h4: (props: any) => (
    <Text fontSize={'sm'} fontFamily={'Montserrat'} color={color} fontWeight='bold'>
      {props.children}
    </Text>
  ),
  ul: (props: any) => (
    <UnorderedList fontSize={'sm'} fontFamily={'Montserrat'} color={color} spacing={2}>
      {props.children}
    </UnorderedList>
  ),
  ol: (props: any) => (
    <OrderedList fontSize={'sm'} fontFamily={'Montserrat'} color={color} spacing={2}>
      {props.children}
    </OrderedList>
  ),
});
