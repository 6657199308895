import {
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  VStack,
  chakra,
  useClipboard,
} from '@chakra-ui/react';
import { useSetAtom } from 'jotai';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { profileTabIndex } from '../../../Atoms/Profile/profile';
import { Routes } from '../../../Constants/constants';
import useMixpanel from '../../../lib/Analytics/Mixpanel/useMixpanel'; // @ts-ignore
import { ReactComponent as _COPY_ICON } from './../../../Assets/icons/copy-button.svg'; // @ts-ignore
import { ReactComponent as _FACEBOOK_ICON } from './../../../Assets/icons/facebook.svg'; // @ts-ignore
import { ReactComponent as _MAIL_ICON } from './../../../Assets/icons/mail.svg'; // @ts-ignore
import { ReactComponent as _WHATSAPP_ICON } from './../../../Assets/icons/whatsapp.svg'; // @ts-ignore
import { ReactComponent as _X_ICON } from './../../../Assets/icons/x.svg';

interface ReferralModalProps {
  isOpen: boolean;
  onClose: () => void;
  imgSrc: string;
  referralLink: string;
  subHeading: React.ReactNode;
  referralReward: string;
}

const COPY_ICON = chakra(_COPY_ICON);
const WHATSAPP_ICON = chakra(_WHATSAPP_ICON);
const FACEBOOK_ICON = chakra(_FACEBOOK_ICON);
const X_ICON = chakra(_X_ICON);
const MAIL_ICON = chakra(_MAIL_ICON);

const ReferralModal: React.FC<ReferralModalProps> = ({
  isOpen,
  onClose,
  imgSrc,
  referralLink,
  subHeading,
  referralReward,
}) => {
  const { onCopy } = useClipboard(referralLink);
  const navigate = useNavigate();
  const setProfileTabIndex = useSetAtom(profileTabIndex);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { trackEvent } = useMixpanel();

  const handleCopyClick = () => {
    onCopy();
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 1000);
    trackEvent('Referral Link Copied on Modal', {
      modalName: referralReward,
    });
  };

  const navigateToReferralSection = () => {
    trackEvent('Referral Learn more Tapped', {
      modalName: referralReward,
    });
    navigate(Routes.Profile);
    setProfileTabIndex(3);
    onClose();
  };

  const message = `Join Kollegio through my referral link and boost your applications!\n
  Tap the link below to get started:\n${referralLink}`;

  const whatsappLink = `https://api.whatsapp.com/send?text=${message}`;
  const mailLink = `mailto:?subject=Join Kollegio &body=${message}`;
  const facebookLink = `https://www.facebook.com/share.php?u=${message}`;
  const XLink = `https://twitter.com/intent/tweet?text=${message}`;

  const renderHeader = () => (
    <Text fontWeight={700} fontSize={'md'} fontStyle={'normal'} lineHeight={'normal'}>
      Invite friends
    </Text>
  );

  const renderFooter = () => (
    <HStack spacing={2} justifyContent='flex-start' width={'100%'}>
      <IconButton
        as='a'
        href={whatsappLink}
        target='_blank'
        rel='noopener noreferrer'
        aria-label='Share on WhatsApp'
        icon={<WHATSAPP_ICON />}
        background={'transparent'}
        _hover={{
          backgroundColor: 'transparent',
        }}
        size='lg'
      />
      <IconButton
        as='a'
        href={XLink}
        target='_blank'
        rel='noopener noreferrer'
        aria-label='Share on X'
        icon={<X_ICON />}
        background={'transparent'}
        _hover={{
          backgroundColor: 'transparent',
        }}
        size='lg'
      />
      <IconButton
        as='a'
        href={mailLink}
        target='_blank'
        rel='noopener noreferrer'
        aria-label='Email referral link'
        icon={<MAIL_ICON />}
        background={'transparent'}
        _hover={{
          backgroundColor: 'transparent',
        }}
        size='lg'
      />
      <IconButton
        as='a'
        href={facebookLink}
        target='_blank'
        rel='noopener noreferrer'
        aria-label='Share on Facebook'
        icon={<FACEBOOK_ICON />}
        background={'transparent'}
        _hover={{
          backgroundColor: 'transparent',
        }}
        size='lg'
      />
    </HStack>
  );

  const renderBody = () => (
    <VStack width={'100%'} alignItems={'flex-start'} spacing={4}>
      {React.isValidElement(subHeading) && subHeading}
      <VStack width={'100%'} alignItems={'center'} padding={2}>
        <Image src={imgSrc} alt='Invite friends' mb={4} />
      </VStack>
      <VStack spacing={0} width={'100%'} alignItems={'flex-start'}>
        <Text fontSize={'md'} fontWeight={500} mb={2} lineHeight={'150%'} fontStyle={'normal'}>
          Copy your referral link
        </Text>
        <HStack width={'100%'}>
          <InputGroup>
            <Input
              value={referralLink}
              padding={'12px 16px'}
              isReadOnly
              fontSize={'14px'}
              color={'gray13'}
              fontStyle={'normal'}
              fontWeight={500}
              lineHeight={'140%'}
              borderRadius={'8px'}
              border={'1px solid'}
              borderColor={'gray12'}
              focusBorderColor='primary'
            />
            <InputRightElement>
              <Tooltip label='Copied!' isOpen={tooltipOpen} placement='top' hasArrow closeOnClick={false}>
                <IconButton
                  background={'transparent'}
                  aria-label='Copy referral link'
                  icon={<COPY_ICON />}
                  onClick={handleCopyClick}
                  _hover={{
                    backgroundColor: 'transparent',
                  }}
                />
              </Tooltip>
            </InputRightElement>
          </InputGroup>
        </HStack>
      </VStack>
      <HStack width={'100%'}>
        <Text mt={2} fontSize={'14px'} fontWeight={500} color='gray17' lineHeight={'140%'} fontStyle={'normal'}>
          Curious about how rewards work?{' '}
          <Link textDecorationLine={'underline'} color='primary' onClick={navigateToReferralSection}>
            Learn more here
          </Link>
        </Text>
      </HStack>
    </VStack>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={['sm', 'md']} isCentered trapFocus={false}>
      <ModalOverlay />
      <ModalContent borderRadius={'12px'}>
        <ModalHeader borderBottom={'1px solid'} borderColor={'gray12'}>
          {renderHeader()}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody paddingX={6} paddingY={4}>
          {renderBody()}
        </ModalBody>
        <ModalFooter borderTop={'1px solid'} borderColor={'gray12'} paddingX={4}>
          {renderFooter()}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReferralModal;
