import { Routes } from 'Constants/constants';

export const convertEssayWriterRoute = (pathname: string) => {
  const essayEditorPathPattern = /^\/essays\/editor\/\d+$/;

  if (essayEditorPathPattern.test(pathname)) {
    return Routes.EssayWriter;
  }

  return pathname;
};
