import { ESSAY_FEATURES_TAB } from 'Constants/essayWriter';
import { atom } from 'jotai';
import { EnvId } from 'Models/config';
import { IEssay } from 'Models/essays';
import ReactQuill from 'react-quill';

export const quillRefAtom = atom<ReactQuill | null>(null);
export const editorHeaderRefAtom = atom<any | null>(null);

interface IEssayAtom {
  selectedText: string;
  selectedTextWordCount: number;
  selectedFeatureTab: ESSAY_FEATURES_TAB | null;
  selectedAITool: ESSAY_FEATURES_TAB | null;
  prompt: string;
  wordLimit: string;
  promptExpanded: boolean;
}

export const essayAtom = atom<IEssayAtom>({
  selectedText: '',
  selectedTextWordCount: 0,
  selectedFeatureTab: ESSAY_FEATURES_TAB.AskKollegio,
  selectedAITool: null,
  prompt: '',
  wordLimit: '',
  promptExpanded: false,
});

export const essayInfoAtom = atom<IEssay | null>(null);

export const essayIdAtom = atom((get) => {
  const essay = get(essayInfoAtom);
  return essay?.id;
});

export const essayNameAtom = atom(
  (get) => get(essayInfoAtom)?.name ?? '',
  (get, set, newName: string) => {
    const essay = get(essayInfoAtom);

    if (essay) {
      set(essayInfoAtom, { ...essay, name: newName });
    }
  }
);

export const essayCharCountAtom = atom(
  (get) => get(essayInfoAtom)?.charcount ?? '',
  (get, set, newCharCount: string) => {
    const essay = get(essayInfoAtom);

    if (essay) {
      set(essayInfoAtom, { ...essay, charcount: newCharCount });
    }
  }
);

export const essayWordCountAtom = atom(
  (get) => get(essayInfoAtom)?.wordcount ?? '',
  (get, set, newWordCount: string) => {
    const essay = get(essayInfoAtom);

    if (essay) {
      set(essayInfoAtom, { ...essay, wordcount: newWordCount });
    }
  }
);

export const essayPromptAtom = atom(
  (get) => get(essayInfoAtom)?.prompt ?? '',
  (get, set, newPrompt: string) => {
    const essay = get(essayInfoAtom);

    if (essay) {
      set(essayInfoAtom, { ...essay, prompt: newPrompt });
    }
  }
);

export const essayPromptExpandedAtom = atom(
  (get) => get(essayAtom).promptExpanded,
  (get, set, newPromptExpanded: boolean) => {
    const essay = get(essayAtom);

    if (essay) {
      set(essayAtom, { ...essay, promptExpanded: newPromptExpanded });
    }
  }
);

export const essayPlanAtom = atom(
  (get) => get(essayInfoAtom)?.plan ?? '',
  (get, set, newPlan: string) => {
    const essay = get(essayInfoAtom);

    if (essay) {
      set(essayInfoAtom, { ...essay, plan: newPlan });
    }
  }
);

export const essayTopicAtom = atom(
  (get) => get(essayInfoAtom)?.topic ?? '',
  (get, set, newTopic: string) => {
    const essay = get(essayInfoAtom);

    if (essay) {
      set(essayInfoAtom, { ...essay, topic: newTopic });
    }
  }
);

export const selectedFeatureTabAtom = atom(
  (get) => {
    const essay = get(essayAtom);
    return essay.selectedFeatureTab;
  },
  (get, set, newFeatureTab: ESSAY_FEATURES_TAB) => {
    const essay = get(essayAtom);
    set(essayAtom, { ...essay, selectedFeatureTab: newFeatureTab });
  }
);

export const selectedAIToolAtom = atom(
  (get) => {
    const essay = get(essayAtom);
    return essay.selectedAITool;
  },
  (get, set, newAITool: ESSAY_FEATURES_TAB) => {
    const essay = get(essayAtom);
    set(essayAtom, { ...essay, selectedAITool: newAITool });
  }
);

export const selectedTextAtom = atom((get) => {
  const essay = get(essayAtom);
  return essay?.selectedText;
});

export const promptAtom = atom((get) => {
  const essay = get(essayAtom);
  return essay?.prompt;
});
export const wordLimitAtom = atom((get) => {
  const essay = get(essayAtom);
  return essay?.wordLimit;
});

export const toolBarRefAtom = atom<HTMLDivElement | null>(null);

if (process.env.REACT_APP_NODE_ENV === EnvId.DEV) {
  essayAtom.debugLabel = 'essayAtom';
}
