import { useAtom, useSetAtom } from 'jotai';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { alertAtom } from '../../Atoms/alertAtom.ts';
import { dashboardAtom } from '../../Atoms/dashboardAtom';
import { ALERT_TYPES, Routes } from '../../Constants/constants';
import authService from '../../Services/auth';
import { getStoredQueryParams, triggerSessionCount } from '../../Utils/auth.ts';
import useMixpanel from '../../lib/Analytics/Mixpanel/useMixpanel';
import usePixel from '../App/usePixel.tsx';
import { userAtom } from './../../Atoms/App/user';
import { getCookie } from './../../Utils/common';

const useAuth = () => {
  const setUser = useSetAtom(userAtom);
  const { identifyUser, resetUser, trackEvent } = useMixpanel();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dashboard, setDashboard] = useAtom(dashboardAtom);
  const setUserAtom = useSetAtom(userAtom);
  const setAlert = useSetAtom(alertAtom);
  const { trackCustomEvent } = usePixel();

  const navigate = useNavigate();

  const pageVisits = {
    home: false,
    profile: false,
    colleges: false,
    essay_selection: false,
    essay_editor: false,
    activities: false,
    access_demo: false,
    planner: false,
  };

  const signUp = async (apiParams: any) => {
    setIsLoading(true);
    const questionnaire = getCookie('questionnaireResponse');
    let parsedQuestionnaire = {};

    if (questionnaire) {
      try {
        parsedQuestionnaire = JSON.parse(questionnaire);
      } catch (e) {
        console.error(e);
      }
    }

    authService
      .signUp({
        ...apiParams,
        questionnaire: parsedQuestionnaire,
      })
      .then(async (data) => {
        if (!data) return;

        setUser({ ...data });
        setUserAtom({ ...data });
        localStorage.setItem('user', JSON.stringify(data));
        setIsLoading(false);

        if (data?.status) {
          setError(data?.status);
        } else if (data.loggedIn) {
          const { utmParams, referralParams, variant } = getStoredQueryParams();

          trackEvent('Sign Up Success, email not verified yet', {
            email: data?.email,
            userId: data?.id,
            variant,
            ...utmParams,
            ...referralParams,
          });

          trackCustomEvent('Sign Up Success', {
            email: data?.email,
            contents: ['kollegio'],
          });

          if (data.email_verified) {
            await identifyUser(data);
            setDashboard({ ...dashboard, isMobilePopUpVisible: true });
            navigate(Routes.Home);
          } else {
            navigate(Routes.Verify);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };

  const login = (apiParams: any) => {
    setIsLoading(true);
    authService
      .login(apiParams)
      .then(async (data) => {
        setIsLoading(false);

        if (!data) return;

        setUserAtom({ ...data });
        setUser({ ...data });

        localStorage.setItem('user', JSON.stringify(data));

        if (data?.status) {
          setError(data?.status);
          return;
        }

        if (data.loggedIn) {
          if (data?.email_verified) {
            await identifyUser(data);
            const { utmParams, referralParams } = getStoredQueryParams();

            trackEvent('Sign In Success', {
              email: data.email,
              userId: data.id,
              ...utmParams,
              ...referralParams,
            });

            setDashboard({ ...dashboard, isMobilePopUpVisible: true });

            if (data?.first_time_login) {
              localStorage.setItem('visited', JSON.stringify({ user: { id: data.id }, pageVisits }));
              localStorage.setItem('sessionCount', (1).toString());
              navigate(Routes.FirstTimeUX);
            } else {
              navigate(Routes.Home);
            }
          } else {
            navigate(Routes.Verify);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };

  const logOut = async () => {
    authService.logOut().then(async (data) => {
      if (!data) return;
      document.cookie = 'auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      localStorage.removeItem('user');
      sessionStorage.removeItem('referralModalShown');

      setUser({ ...data });
      setUserAtom({ ...data });
      await resetUser();
      navigate(Routes.Default);
      triggerSessionCount();
    });
  };

  const forgotPassword = (apiParams: any) => {
    authService.forgotPassword(apiParams).then((data) => {
      if (data.error) {
        setSuccess(false);
        setError(data.error.message);
        return;
      }

      if (!data) return;
      setError(null);
      setSuccess(data?.data?.message);
    });
  };

  const resetPassword = (apiParams: any) => {
    authService.resetPassword(apiParams).then((data) => {
      if (!data) return;

      if (data.error) {
        setError(data.error.message);
        setSuccess(false);
      } else {
        setError(null);
        setSuccess(true);
      }
    });
  };

  const updatePassword = (payload, onSuccess) => {
    authService
      .changePassword(payload)
      .then(() => {
        setAlert({
          isVisible: true,
          message: 'Password Updated',
          type: ALERT_TYPES.SUCCESS,
        });
        onSuccess();
      })
      .catch(() => {
        setAlert({
          isVisible: true,
          message: 'Password not updated',
          type: ALERT_TYPES.ERROR,
        });
      });
  };

  return {
    isLoading,
    error,
    setError,
    success,
    setSuccess,
    login,
    signUp,
    logOut,
    forgotPassword,
    resetPassword,
    updatePassword,
  };
};

export default useAuth;
