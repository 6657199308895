import bean from 'Assets/icons/bean.svg';
import { COLLEGE_DEADLINE_TYPES } from 'Constants/constants';

const transformFieldOfStudy = (field: any) =>
  field
    .split('_')
    ?.map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

const fieldsOfStudy = [
  // Supported fields of study (majors)
  'business_and_management',
  'nursing',
  'psychology',
  'biology',
  'engineering',
  'education',
  'communications',
  'finance_and_accounting',
  'criminal_justice',
  'anthropology_and_sociology',
  'computer_science',
  'english',
  'political_science',
  'history',
  'kinesiology_and_physical_therapy',
  'health_professions',
  'art_and_design',
  'math',
  'environmental_science',
  'foreign_languages',
  'trades_and_personal_services',
  'international_relations',
  'physics',
  'music',
  'chemistry',
];

/**
 * Retrieves the image URL for a given college name.
 * @param {string} name - The name of the college.
 * @returns {string} The image URL for the college logo.
 */
export const getImage = (name: string) => {
  const cdnBaseUrl = `https://imagedelivery.net/tlOJgCi1A5ET_QnIUWyeeg/${name?.replace(/\s/g, '_')}_logo.webp/public`;

  const img = new Image();
  img.src = cdnBaseUrl;

  console.error(`Error loading image: ${cdnBaseUrl}`);

  return img.complete ? cdnBaseUrl : bean;
};

export const handleImageError = (event: any) => {
  event.target.src = bean;
};

export const formatGPA = (gpa: any) => Number(gpa).toFixed(2);

export const formatPercentage = (percentage: any) => Number(percentage).toFixed(0);

export const chakraMultiSelectStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '100%',
    height: '100%',
    fontSize: '13px',
  }),
  control: (provided: any) => ({
    ...provided,
    backgroundColor: '#F9FAFB',
    color: '#98A2B3',
    border: '1px #D0D4DB solid',
    borderRadius: '12px',
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: '#1e2435',
    color: 'white',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    flexWrap: 'wrap',
    overflowY: 'auto',
    maxHeight: '40px',
  }),
  multiValueContainer: (provided: any) => ({
    ...provided,
    background: 'white',
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: '#3E8A75',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#E1EFF9' : 'white',
    color: state.isFocused ? '#1e2435' : '#1e2435',
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: 'white',
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: '#045A41',
    '&:hover': {
      color: '#3E8A75',
    },
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
};

export const cityOptions = [{ value: 'Boston', label: 'Boston' }];

export const statusOptions = [
  { value: 'Default', label: 'Default' },
  { value: 'Reach', label: 'Reach' },
  { value: 'Target', label: 'Target' },
  { value: 'Safety', label: 'Safety' },
];

export const sortOptionsAcademic = [
  {
    label: 'Default',
    options: [{ value: 'times_us_rank_asc', label: 'Default' }],
  },
  {
    label: 'Academic',
    options: [
      { value: 'mean_gpa_asc', label: 'Weighted GPA (Ascending)' },
      { value: 'mean_gpa_desc', label: 'Weighted GPA (Descending)' },
      { value: 'mean_sat_score_asc', label: 'SAT (Ascending)' },
      { value: 'mean_sat_score_desc', label: 'SAT (Descending)' },
      { value: 'mean_act_score_asc', label: 'ACT (Ascending)' },
      { value: 'mean_act_score_desc', label: 'ACT (Descending)' },
      { value: 'admission_rate_asc', label: 'Admissions Rate (Ascending)' },
      { value: 'admission_rate_desc', label: 'Admissions Rate (Descending)' },
      {
        value: 'majors_available_desc',
        label: 'Majors Available (Descending)',
      },
      {
        value: 'four_year_graduation_rate_desc',
        label: 'Four Year Graduation Rate (Descending)',
      },
    ],
  },
];

export const sortOptionsCampus = [
  {
    label: 'Default',
    options: [{ value: 'times_us_rank_asc', label: 'Default' }],
  },
  {
    label: 'Campus',
    options: [
      {
        value: 'international_students_percentage_desc',
        label: '% of International Students',
      },
      {
        value: 'student_faculty_ratio_asc',
        label: 'Student Faculty Ratio (Ascending)',
      },
      {
        value: 'student_faculty_ratio_desc',
        label: 'Student Faculty Ratio (Descending)',
      },
      {
        value: 'minority_enrollment_desc',
        label: 'Minority Enrollment',
      },
      {
        value: 'undergraduate_students_asc',
        label: 'Undergraduate Enrollment (Ascending)',
      },
      {
        value: 'undergraduate_students_desc',
        label: 'Undergraduate Enrollment (Descending)',
      },
      { value: 'food_asc', label: 'Food' },
      { value: 'social_life_asc', label: 'Social Life' },
    ],
  },
];

export const sortOptionsDeadlines = [
  {
    label: 'Default',
    options: [{ value: 'times_us_rank_asc', label: 'Default' }],
  },
  {
    label: 'Deadlines',
    options: [
      {
        value: 'rd_deadline_asc',
        label: 'Regular Decision Deadline (Earliest)',
      },
      {
        value: 'rd_deadline_desc',
        label: 'Regular Decision Deadline (Latest)',
      },
      { value: 'ed_deadline_asc', label: 'Early Decision Deadline (Earliest)' },
      { value: 'ed_deadline_desc', label: 'Early Decision Deadline (Latest)' },
    ],
  },
];

export const sortOptionsFinancial = [
  {
    label: 'Default',
    options: [{ value: 'times_us_rank_asc', label: 'Default' }],
  },
  {
    label: 'Financial',
    options: [
      {
        value: 'average_aid_package_desc',
        label: 'Average Aid Package',
      },
      {
        value: 'students_receive_aid_percentage_desc',
        label: '% of Students Receiving Aid',
      },
      { value: 'alumni_starting_salary_desc', label: 'Alumni Starting Salary' },
    ],
  },
];

export const sortOptionsRankings = [
  {
    label: 'Default',
    options: [{ value: 'times_us_rank_asc', label: 'Default' }],
  },
  {
    label: 'Major Rankings',
    options: [
      ...fieldsOfStudy.map((field) => ({
        value: `${field}_rank_asc`,
        label: `${transformFieldOfStudy(field)}`,
      })),
    ],
  },
];

export const sortOptions = [
  {
    label: 'Default',
    options: [{ value: 'times_us_rank_asc', label: 'Default' }],
  },
  {
    label: 'Academic',
    options: [
      { value: 'mean_gpa_asc', label: 'Weighted GPA (Ascending)' },
      { value: 'mean_gpa_desc', label: 'Weighted GPA (Descending)' },
      { value: 'mean_sat_score_asc', label: 'SAT (Ascending)' },
      { value: 'mean_sat_score_desc', label: 'SAT (Descending)' },
      { value: 'admission_rate_asc', label: 'Admissions Rate (Ascending)' },
      { value: 'admission_rate_desc', label: 'Admissions Rate (Descending)' },
    ],
  },
  {
    label: 'Campus',
    options: [
      {
        value: 'international_students_percentage_desc',
        label: '% of International Students',
      },
      { value: 'food_asc', label: 'Food' },
      { value: 'social_life_asc', label: 'Social Life' },
    ],
  },
  {
    label: 'Major Rankings',
    options: [
      ...fieldsOfStudy.map((field) => ({
        value: `${field}_rank_asc`,
        label: `${transformFieldOfStudy(field)}`,
      })),
    ],
  },
];

export const processDeadlines = (college: any) => {
  const today = new Date();

  const deadlines = [
    {
      deadline: college.rea_deadline,
      type: COLLEGE_DEADLINE_TYPES.RESTRICTIVE_EARLY_ACTION,
    },
    {
      deadline: college.ea_deadline,
      type: COLLEGE_DEADLINE_TYPES.EARLY_ACTION,
    },
    {
      deadline: college.ed_deadline,
      type: COLLEGE_DEADLINE_TYPES.EARLY_DECISION,
    },
    {
      deadline: college.ed2_deadline,
      type: COLLEGE_DEADLINE_TYPES.EARLY_DECISON_II,
    },
    {
      deadline: college.rd_deadline,
      type: COLLEGE_DEADLINE_TYPES.REGULAR_DECISON,
    },
  ]
    .map((item) => {
      const dateString = item.deadline?.trim();
      return isNaN(Date.parse(dateString)) ? null : { deadline: new Date(dateString), type: item.type };
    })
    .filter(Boolean);

  deadlines.sort((a: any, b: any) => a.deadline - b.deadline);

  const closestDeadline = deadlines.find((item: any) => item.deadline > today) || null;

  return { ...college, closestDeadline };
};

export const handleStandardisedScores = (vals: any) => {
  let new_act_score;

  if (vals?.act !== '' && vals?.act !== undefined) {
    new_act_score = (vals?.act / 36) * 1600;

    if (vals.satmath !== '' && vals.satmath !== undefined && vals.satenglish !== '' && vals.satenglish !== undefined) {
      const total_sat_score = parseInt(vals.satmath, 10) + parseInt(vals.satenglish, 10);

      if (Math.max(new_act_score, total_sat_score) === new_act_score) {
        return {
          math: Math.floor(new_act_score / 2),
          english: Math.floor(new_act_score / 2),
        };
      }

      return {
        math: parseInt(vals.satmath, 10),
        english: parseInt(vals.satenglish, 10),
      };
    }

    return {
      math: Math.floor(new_act_score / 2),
      english: Math.floor(new_act_score / 2),
    };
  }

  const math_score = vals.satmath === '' ? undefined : vals.satmath;
  const english_score = vals.satenglish === '' ? undefined : vals.satenglish;
  return {
    math: parseInt(math_score, 10),
    english: parseInt(english_score, 10),
  };
};

export const getCollegeReachTag = (college: any, user: any) => {
  const satScore = handleStandardisedScores(user).math + handleStandardisedScores(user).english || 1055;

  const gpa = user.gpa || 3.0;

  if (!college?.admission_rate) {
    return 'Safety';
  }

  if (!college?.mean_sat_score && !college?.mean_gpa) {
    const reachCutOff = 0.08;
    const targetCutOff = 0.15;

    if (college?.admission_rate < reachCutOff) {
      return 'Reach';
    } else if (college?.admission_rate < targetCutOff) {
      return 'Target';
    }

    return 'Safety';
  }

  let score = 0;

  const satDiff = satScore - (college.mean_sat_score || (college.mean_gpa / 5.0) * 1600.0);

  const gpaDiff = gpa - (college.mean_gpa || (college.mean_sat_score / 1600.0) * 5.0);

  const admissionRate = college?.admission_rate;

  score += satDiff / 50;
  score += gpaDiff / 0.2;

  if (satDiff > 0 && gpaDiff > 0) {
    score += 1;
  } else if (satDiff < 0 && gpaDiff < 0) {
    score -= 1;
  }

  if (admissionRate < 0.09) {
    score -= 5;
  } else if (admissionRate > 0.2) {
    score += 5;
  }

  if (admissionRate > 0.389) {
    score += 5;
  }

  // -5 to 5 scale, -5 to -1 is reach
  // 0 to 2 is target, 3 to 5 is safety
  if (score <= 1.5) {
    return 'Reach';
  } else if (score <= 10) {
    return 'Target';
  }

  return 'Safety';
};

export const getTooltipText = (reachTag?: string) => {
  switch (reachTag?.toLowerCase()) {
    case 'reach':
      return 'Highly competitive, aspirational choice';
    case 'target':
      return 'Well-matched colleges with good chances';
    case 'safety':
      return 'Secure options with easier admission';
    default:
      return 'Oops! We were unable to determine the tag ...';
  }
};

export const getDeadlineTags = () => ({ bg: 'none', color: 'gray4' });

export const getTag = (reachTag?: string) => require(`../../Assets/icons/${reachTag}.svg`);
