import { Routes } from 'Constants/constants';
import { EnvId } from 'Models/config';
import { atom } from 'jotai';

interface Demo {
  showJoyride: boolean;
  manualTrigger: boolean;
  index: number;
  screen: Routes;
  isEditorOpen: boolean;
  isApplicationBackgroundOpen: boolean;
  isPlannerCollegesListOpen: boolean;
  isFirstSkip: boolean;
  isNavDemoShown: boolean;
  steps: any[];
  isControlled: boolean;
  firstEssayId: number | null;
}

export const demoAtom = atom<Demo>({
  showJoyride: false,
  manualTrigger: false,
  index: 0,
  screen: Routes.Default,
  isEditorOpen: false,
  isApplicationBackgroundOpen: false,
  isPlannerCollegesListOpen: false,
  isFirstSkip: false,
  steps: [],
  isControlled: false,
  isNavDemoShown: false,
  firstEssayId: null,
});

if (process.env.REACT_APP_NODE_ENV === EnvId.DEV) {
  demoAtom.debugLabel = 'demo';
}

export const isApplicationBackgroundOpenAtom = atom(
  (get) => get(demoAtom)?.isApplicationBackgroundOpen,
  (get, set, newValue: boolean) => set(demoAtom, { ...get(demoAtom), isApplicationBackgroundOpen: newValue })
);

export const firstEssayIdAtom = atom(
  (get) => get(demoAtom)?.firstEssayId,
  (get, set, newValue: number) => set(demoAtom, { ...get(demoAtom), firstEssayId: newValue })
);

export const manualTriggerAtom = atom(
  (get) => get(demoAtom)?.manualTrigger,
  (get, set, newValue: boolean) => set(demoAtom, { ...get(demoAtom), manualTrigger: newValue })
);

export const demoChoiceLinkAtom = atom<string>('');
demoChoiceLinkAtom.debugLabel = 'demoChoiceIndex';
