import { CloseIcon } from '@chakra-ui/icons';
import { HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import '../../Styles/drawer.css';

const NotesComponent = ({ children, onClose }: any) => (
  <VStack
    spacing={2}
    width={'100vw'}
    height={'100vh'}
    alignItems={'flex-start'}
    padding={4}
    backgroundColor={'white'}
    position={'fixed'}
  >
    <HStack justifyContent={'space-between'} w='full'>
      <Text color={'primary'} fontWeight={700} fontSize={'24px'}>
        Notes
      </Text>
      <CloseIcon boxSize={3} onClick={onClose} cursor='pointer' />
    </HStack>
    {children}
  </VStack>
);

export default NotesComponent;
